// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contact-confirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-newsletter-confirmation-js": () => import("./../../../src/pages/newsletter-confirmation.js" /* webpackChunkName: "component---src-pages-newsletter-confirmation-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-school-page-js": () => import("./../../../src/templates/school-page.js" /* webpackChunkName: "component---src-templates-school-page-js" */),
  "component---src-templates-text-page-js": () => import("./../../../src/templates/text-page.js" /* webpackChunkName: "component---src-templates-text-page-js" */)
}

